import { render, staticRenderFns } from "./Writing.vue?vue&type=template&id=871a3abe&scoped=true&"
import script from "@/assets/js/components/article/practicing/write/Writing.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/article/practicing/write/Writing.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/article/practicing/write/Writing.scss?vue&type=style&index=0&id=871a3abe&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "871a3abe",
  null
  
)

export default component.exports